import axios from 'axios'


const CartApi = {
    getCart: async (token: string) => {
        try {
            const response = await axios.get(`${process.env.API_URL}/api/v1.0/listing/cart/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response;
        } catch (error) {
            return error;
        }
    },
    addToCart: async (token: string, listingId: string | number, quantity: number) => {
        let data = {
            listingId,
            quantity
        }
        try {
            const response = await axios.post(`${process.env.API_URL}/api/v1.0/listing/cart`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response;
        } catch (error) {
            return error;
        }
    },
    removeFromCart: async (token: string, listingId: string) => {
        let data = {
            listingId
        }
        try {
            const response = await axios.delete(`${process.env.API_URL}/api/v1.0/listing/cart`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data
            });
            return response;
        } catch (error) {
            return error;
        }
    }
}

export default CartApi